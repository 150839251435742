<template>
  <div class="DeveloperUserItem" @click.prevent="loadDeveloperUserProjects()">
    <div class="DeveloperUserItem__username">{{ user.username }}</div>
    <div class="DeveloperUserItem__email">{{ user.email }}</div>
  </div>
</template>

<script>
export default {
  name: 'DeveloperUserItem',
  props: {
    user: {
      type: Object,
      required: true,
    },
    userindex: {
      type: Number,
      required: true,
    },
  },
  methods: {
    loadDeveloperUserProjects() {
      this.$store.dispatch('setCurrentDeveloperUser', this.user);
      this.$router.push({
        name: 'developerUserProjects',
        params: {
          userId: this.user.userId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/components/developer/developer-users/DeveloperUserItem.scss';
</style>
